import React from 'react';

function NotFound() {
  return (
    <div>
      <h1>A hivatkozott oldal nem található.</h1>
    </div>
  )
}

export default NotFound;
